<!-- 第一页 -->
<template>
<div class="app-box outBox">
  <!-- 外层容器 -->
  <div style="width:100%;height:100%">
    <!-- 底部的装饰 -->
    <div class="bottomDecoration">
      <img :src="bottomDecoration" alt="" style="width:100%;height:100%">
    </div>
    <!-- 登录的盒子 -->
    <div class="loginBox">
        <!-- 内容-右 -->
        <div class="contentBox">
            <div class="login">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-row style="margin-bottom:0.2rem">
                        <el-col :span="24">
                            <el-form-item label="当前密码" prop="nowPassword">
                                <el-input v-model="ruleForm.nowPassword"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row style="margin-bottom:0.2rem">
                        <el-col :span="24">
                            <el-form-item label="新密码" prop="nowPassword">
                                <el-input v-model="ruleForm.newPassword"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row style="margin-bottom:0.2rem">
                        <el-col :span="24">
                            <el-form-item label="确认密码" prop="confirmPassword">
                                <el-input v-model="ruleForm.nowPassword"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>           
                    <el-row>
                        <el-col :span="24">
                            <el-form-item>
                                <el-button type="primary" @click="submitForm('ruleForm')" class="fl">确认</el-button>
                                <el-button @click="goback" class="fl">返回</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </div>
    </div>
  </div>
</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
  import bottomPiv from '../../../static/images/bottom.png'
export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
//这里存放数据
return {
    bottomDecoration:bottomPiv,
    ruleForm:{
        nowPassword:"",//当前密码
        newPassword:"",//新密码
        confirmPassword:""//确认密码
    },
    rules: {
        nowPassword:[{ required: true, message: '请输入当前密码', trigger: 'blur' }],//当前密码
        newPassword:[{ required: true, message: '请输入新密码', trigger: 'blur' }],//当前密码
        confirmPassword:[{ required: true, message: '请确认密码', trigger: 'blur' }],//当前密码
    }
};
},
//监听属性 类似于data概念
computed: {
  
},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
    submitForm(fromName){
      console.log(fromName);
    },
    goback(){
        this.$router.push("/home/series") 
    }
},
beforeCreate() {}, //生命周期 - 创建之前
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
beforeMount() {}, //生命周期 - 挂载之前
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {

},
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.outBox{
    position: relative;
    background: url(../../../static/images/bgc.png);
  /* background-size: cover; */
}
.bottomDecoration{
  width: 1.6625rem;
  height:1.7625rem;
  /* background-color: #fff; */
  position: absolute;
  bottom:0;
  left:0;
}
.loginBox{
    width: 12.15rem;
    height: 6.0125rem;
    position: absolute;
    left:50%;
    top:50%;
    margin-left:-6.075rem;
    margin-top: -3.00625rem;
    background-color: #fff;
    box-shadow: 0px 6px 48px rgba(148, 148, 148, 0.13);
    border-radius: 8px;
}
/* .logoBox{
    width: 4.525rem;
    height: 6.0125rem;
    position: absolute;
    left:0;
}
.logo{
    width: 3.8rem;
    height: 1.475rem;
    position:absolute;
    top:50%;
    left:50%;
    margin-left: -1.9rem;
    margin-top:-0.7375rem;
} */
.contentBox{
    width: 7.625rem;
    height: 6.0125rem;
    top:50%;
    left:50%;
    margin-left: -3.8125rem;
    margin-top: -3.00625rem;
    position: absolute;
}
.login{
    width: 5.1375rem;
    height: 4.5625rem;
    position: absolute;
    top:50%;
    left:50%;
    margin-left: -2.56875rem;
    margin-top:-2.28125rem;
}
.loginTitle{
    width:100%;
    height:0.925rem;
    font-weight: bold;
    font-size: 0.4875rem;
}
.btn{
    width:100%;
    height:100%;
}
.loginName{
    width:100%;
    height:0.64rem;
    font-size:0.2125rem;
    margin-bottom:0.5rem;
    border:0px;
    outline: none;
    border-bottom:2px solid black;
}
</style>